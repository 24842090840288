import { useMemo } from 'react';
import { Conflux } from "js-conflux-sdk/dist/js-conflux-sdk.umd.min.js";
import useCurrentNetwork from './useCurrentNetwork';

const useController = () => {
  const { network } = useCurrentNetwork();

  return useMemo(() => {
    return new Conflux(network);
  }, [network]);
}

export default useController;
