import React from 'react';
import cfxLogo from '../../images/cfx.svg'
import {useNavigate} from 'react-router-dom'
import dashboardBg from '../../images/dashboard_light.png'

function DashboardHeader() {
  const navigate = useNavigate()
  
  const goStake = () => {
    navigate('/')
  }

  return (
    <div className="bg-center bg-cover" style={{ backgroundImage: `url(${dashboardBg})` }}>
      <header className="p-4 flex justify-between items-center h-28 container mx-auto max-w-[1280px]">
        <div className="flex items-center">
          <img src={cfxLogo} alt="Conflux Logo" className="h-12 mr-4" />
          <h1 className="mb-0 text-lg lg:text-3xl font-bold">Conflux Staking</h1>
        </div>
        <button className="p-4 font-extrabold text-white bg-black rounded-xl" onClick={goStake}>Stake Now</button>
      </header>
    </div>
  );
}

export default DashboardHeader;