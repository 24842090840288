import { useMemo } from 'react';
import useController from './useController';
import { stakeAbi } from '../constants/contractAbi';
import { utils } from 'ethers';
import useCurrentNetwork from '../hooks/useCurrentNetwork';
import poolConfig from '../../pool.config';

const usePoolContract = () => {
    const { isTestNetEnv } = useCurrentNetwork();
    const poolAddress = isTestNetEnv ? poolConfig.testnet.contractAddress : poolConfig.mainnet.contractAddress;
    const controller = useController();

    return useMemo(() => {
        return {
            contract: controller.Contract({
                abi: stakeAbi,
                address: poolAddress,
            }),
            interface: new utils.Interface(stakeAbi)
        }
    }
    , [poolAddress, controller]);
}

export default usePoolContract;
