import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Popover, Divider, Drawer, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../../hooks/useWallet';
import { cutString } from '../../../utils';
import { ExportOutlined, MenuOutlined } from '@ant-design/icons';

import avatarImg from '../../../images/avatar.svg';
import Logo from '../logo';
import CopyButton from '../copy';
import NotAllow from '../../../images/not-allow.png';
import useCurrentNetwork from '../../../hooks/useCurrentNetwork';
import useIsNetworkMatch from '../../../hooks/useIsNetworkMatch';
import "./index.css";
import poolConfig from '../../../../pool.config';

function Header() {
  const { t } = useTranslation();
  const address = useAccount();
  const isNetworkMatch = useIsNetworkMatch();
  const networkError = !isNetworkMatch && address;
  const { network, isTestNetEnv } = useCurrentNetwork();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleRouter = (url) => {
    if (url.startsWith('https')) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
    setDrawerVisible(false);
  };

  const goExplorer = () => {
    const explorerUrl = isTestNetEnv ? poolConfig.testnet.explorer : poolConfig.mainnet.explorer;
    window.open(`${explorerUrl}${address}`, '_blank');
  };

  const goDashboard = () => {
    navigate('/cfx/dashboard');
    setDrawerVisible(false);
  };

  const handleActivate = async () => {
    if (typeof window.conflux === 'undefined') {
      console.error('Fluent Wallet has not been install yet!');
      window.open('https://fluentwallet.com/', '_blank');
      return;
    }
    await window.conflux.request({ method: 'cfx_requestAccounts' });
  };

  const items = [
    { label: 'eSpace', key: 'espace', url: poolConfig.espaceUrl },
    { label: 'Help', key: 'help', url: poolConfig.helpUrl },
    { label: 'Contact', key: 'contact', url: poolConfig.contactUrl },
  ];

  const DrawerContent = () => (
    <div className="flex flex-col h-full">
      <div className="flex items-center mb-8">
        <Logo className="w-8 h-8" />
        <span className="text-lg font-bold ml-4">Stake DxPool</span>
      </div>
      
      <div className="flex flex-col space-y-6">
        {items.map((item) => (
          <div 
            key={item.key} 
            className="text-base font-semibold cursor-pointer"
            onClick={() => handleRouter(item.url)}
          >
            {item.label}
          </div>
        ))}
        <div 
          className="text-base font-semibold cursor-pointer"
          onClick={goDashboard}
        >
          Dashboard
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-[#fbfbfb]">
      <nav className="container mx-auto max-w-[1280px] p-4">
        <div className="flex justify-between items-center font-semibold">
          <div className="flex items-center">
            <Logo />
            <a href="/" className="hidden ml-2 font-bold md:block hover:text-black text-base">
              Stake DxPool
            </a>

            <div className="hidden ml-12 md:flex items-center space-x-12 text-base font-semibold">
              {items.map((data, index) => (
                <div key={index} className="cursor-pointer" onClick={() => handleRouter(data.url)}>
                  {data.label}
                </div>
              ))}
            </div>
          </div>
         
          <div className="flex items-center space-x-4">
            {address ? (
              <Popover
                content={
                  <div className="p-2 w-[300px]">
                    <div className="flex items-center">
                      <img src={avatarImg} alt="global" className="w-6 h-6 mr-2" />
                      <div className="text-base">{cutString(address, 12, 12)}</div>
                      <CopyButton data={address} />
                    </div>
                    <Divider className="!mb-4" />
                    <div onClick={goExplorer} className="text-[#333333] hover:bg-[#d9d9d933] leading-[1.125rem] py-2 px-1 flex items-center cursor-pointer rounded-xl text-sm w-full">
                      <ExportOutlined className="mr-2" />
                      <div className="text-base">View on Explorer</div>
                    </div>
                  </div>
                }
                trigger="click"
              >
                <button className="btn-outline h-8 inline-flex items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold border">
                  {cutString(address, 4, 6)}
                </button>
              </Popover>
            ) : (
              <button
                onClick={handleActivate}
                className="btn-outline h-8 inline-flex items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold border focus:outline-none active:shadow-none active:bg-gray-300 active:border-gray-500 transition-all duration-150 ease-in-out"
              >
                {t('Header.connect_fluent')}
              </button>
            )}

            <div className="hidden md:flex items-center">
              <div className="text-base text-gray-300 mx-4">|</div>
              <div className="text-base font-medium cursor-pointer" onClick={goDashboard}>
                Dashboard
              </div>
            </div>

            <div className="md:hidden">
              <MenuOutlined onClick={() => setDrawerVisible(true)} />
            </div>
          </div>
        </div>
      </nav>

      <Drawer
        title={null}
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        <Menu mode="vertical">
          <DrawerContent />
        </Menu>
      </Drawer>

      {networkError &&
        createPortal(
          <div className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-25 z-50">
            <div className="absolute top-[40%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col justify-center items-center w-[240px] md:w-[480px] md:h-[390px] md:px-[28px] md:py-[12px] bg-white shadow rounded-md">
              <img
                className="w-[340px] h-[240px]"
                src={NotAllow}
                alt="not allow imgs"
              />
              <p className="md:text-[32px] md:leading-[32px] text-[#333] md:my-[16px] font-bold">
                {t('Header.error')}
              </p>
              <p className="md:text-[16px] md:leading-[24px] text-[#999] text-center md:my-0 font-medium">
                {t('Header.unspport_network_switch', { network: network?.name || '' })}
              </p>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}

export default Header;
