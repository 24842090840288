import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto'
import { CategoryScale } from "chart.js";
import RewardChart from "../components/Chart";
import NoData from '../components/NoData';
import { useAccount, useChainId } from '../../hooks/useWallet';
import { getRewardList, getRewardChartData } from "../../api";
import { getRewardTime, exportCsv } from '../../utils';
import { DatePicker } from 'antd';
import { downloadCsv } from '../../api';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import useCurrentNetwork from '../../hooks/useCurrentNetwork';
import moment from 'moment';
// import PaginationComponent from '../components/Pagination';
import PaginationComponent from '../components/Pagination/Pagination';
import './index.css';
import { formatNumber } from '../../utils';

Chart.register(CategoryScale);

function RewardHistory() {
  const chainId = useChainId();
  const account = useAccount();

  const [rewards, setRewards] = useState([]);
  const [timeStampArray, setTimeStampArray] = useState([]);
  const [earnsArray, setEarnsArray] = useState([]);
  const { RangePicker } = DatePicker;
  const [dates, setDates] = useState([moment().subtract(1, 'months'), moment()]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const { isTestNetEnv } = useCurrentNetwork();

  useEffect(() => {
    getUserRewards(current);
    getUserChartData();
  }, [account, chainId, current]);

  // useEffect to update chartData when timeStampArray or earnsArray changes
  useEffect(() => {
    setChartData({
      labels: timeStampArray,
      datasets: [
        {
          label: "CFX Rewards",
          data: earnsArray,
          backgroundColor: [
            "#00cdd0",
          ],
        }
      ]
    });
  }, [timeStampArray, earnsArray]);


  const getUserRewards = async (page) => {
    if (!account) {
      setRewards([]);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet' : 'mainnet',
      address: account,
      page_size: 10,
      offset: (page - 1) * 10
    }
    try {
      const { data } = await getRewardList(params);
      setRewards(data.items);
      setTotal(data.total_count);
    } catch (error) {
      setRewards([]);
    }
  }

  const handleDateFormate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      console.error("Invalid date format");
      return null;
    }
    const formattedDate = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1).toString().padStart(2, '0')}${date.getUTCDate().toString().padStart(2, '0')}`;
    return formattedDate;
  };

  const getUserChartData = async () => {
    if (!account) {
      setTimeStampArray([]);
      setEarnsArray([]);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet' : 'mainnet',
      address: account,
      offset: 0,
      page_size: 30,
    }
    const { data } = await getRewardChartData(params);
    setTimeStampArray(data.items.map((item) => getRewardTime(item.day)));
    setEarnsArray(data.items.map((item) => item.earn));
  }

  const handlePageChange = (page) => {
    setCurrent(page);
  }

  const handleDateChange = (dates) => {
    setDates(dates);
  };

  const handleDownload = async () => {
    if (!dates || dates.length === 0) {
      return;
    }

    const params = {
      address: account,
      start_day: handleDateFormate(dates[0]),
      end_day: handleDateFormate(dates[1]),
      network_type: isTestNetEnv ? 'testnet' : 'mainnet',
    }

    const { data } = await downloadCsv(params);
    exportCsv(data, `reward_history_${account}`);
  }

  const [chartData, setChartData] = useState({
    labels: timeStampArray,
    datasets: [
      {
        label: "CFX Rewards",
        data: earnsArray,
        backgroundColor: '#00cdd0', // Set all bars to the same background color
      }
    ]
  });


  return (
    <div className="my-6">
      <div className="flex items-center justify-between">
        <h2 className="mt-10 mb-4 text-lg font-semibold">Reward History</h2>
      </div>

      {rewards.length === 0 ? (
        <NoData />
      ) : (
        <div className="lg:flex">
          <div className="w-full h-full p-8 mr-0 bg-base-200 rounded-2xl lg:w-1/2 lg:mr-12">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full align-middle">
                <div className="flex justify-end mb-4">
                  <RangePicker
                    defaultValue={[moment().subtract(1, 'months'), moment()]}
                    onChange={handleDateChange}
                    dateRender={(current) => {
                      const style = {};
                      if (current.date() === 1) {
                        style.border = '1px solid #1890ff';
                        style.borderRadius = '50%';
                      }
                      return (
                        <div className="ant-picker-cell-inner" style={style}>
                          {current.date()}
                        </div>
                      );
                    }}
                  />
                  <button
                    className="flex items-center justify-center h-6 px-2 ml-2 font-medium border rounded-md hover:shadow-inner"
                    onClick={handleDownload}
                  >
                    Download
                  </button>
                </div>

                <table className="w-full border-collapse border-spacing-0">
                  <thead className="bg-[#EFEFEF] rounded-md">
                    <tr>
                      <th className="px-4 py-2 text-left text-gray-text rounded-tl-md">Date</th>
                      <th className="px-4 py-2 text-right text-gray-text rounded-tr-md">CFX Earned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewards.map((reward, index) => (
                      <tr key={index} className="h-8">
                        <td className="flex items-center px-4 py-2 text-sm font-medium text-base-100 ">
                          <span className="pr-1">{reward.day}</span>
                          {(index === 0 && current === 1) && (
                            <Tooltip
                              title="The revenue data is updated every hour."
                              color="white"
                              key="white"
                              overlayInnerStyle={{ color: '#333' }}
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          )}
                        </td>
                        <td className="px-4 py-2 font-medium text-right text-base-100">
                          {formatNumber(reward.earn, 8)}
                          <span className="ml-1 text-accent">CFX</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex justify-end mt-4">
                  <PaginationComponent total={total} pageSize={10} onPageChange={handlePageChange} />
                </div>
              </div>
            </div>

          </div>

          <div className="w-full mt-8 lg:mt-0 lg:w-1/2">
            <RewardChart className="w-1/2" chartData={chartData} />
          </div>
        </div>
      )}
    </div>
  );
}

export default RewardHistory;