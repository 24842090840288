import { useMemo } from 'react';
import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";
import poolConfig from '../../pool.config';
import useCurrentSpace from './useCurrentSpace';
import { useChainId } from './useWallet';

const useCurrentNetwork = () => {
  const currentSpace = useCurrentSpace();
  const chainId = useChainId();

  const isTestNetEnv = useMemo(() => {
    if (chainId) {
      return Number(chainId) === NETWORK_ID_CORE_TESTNET;
    }
    return false;
  }, [chainId]);

  const network = useMemo(() => {
    if (!currentSpace) return null;
    const networkId = isTestNetEnv ? NETWORK_ID_CORE_TESTNET : NETWORK_ID_CORE_MAINNET;

    let url = poolConfig[isTestNetEnv ? 'testnet' : 'mainnet'][currentSpace].RPC;

    return ({
      url,
      networkId,
      name: poolConfig[isTestNetEnv ? 'testnet' : 'mainnet'][currentSpace].name
    });
  }, [currentSpace, isTestNetEnv]);

  return { network, isTestNetEnv };
}

export default useCurrentNetwork;
