import { useChainId as useFluentChainId } from "@cfxjs/use-wallet";
import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";
import { useEffect, useState } from "react";
import useCurrentSpace from "./useCurrentSpace";

const useIsNetworkMatch = () => {
    const allowedNetworkIds = [
      NETWORK_ID_CORE_MAINNET,
      NETWORK_ID_CORE_TESTNET,
    ];
    
    const currentSpace = useCurrentSpace();
    const fluentChainId = useFluentChainId();
    const [usedChainId, setUsedChainId] = useState(null);
  
    useEffect(() => {
      const updateChainId = async () => {
        if (!window.conflux) {
         return
        
        }
        try {
          const newChainIdHex = await window.conflux.request({ method: 'cfx_chainId' });
  
          const newChainId = parseInt(newChainIdHex, 16);
  
          setUsedChainId(newChainId);
        } catch (error) {
          console.error('Error fetching chainId:', error);
          setUsedChainId(null);
        }
      };
  
      updateChainId();
    }, [currentSpace, fluentChainId]);
  
    if (!currentSpace) return true;
  
    return usedChainId !== null && allowedNetworkIds.includes(usedChainId);
  };
  
export default useIsNetworkMatch;