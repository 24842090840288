import { useState, useEffect, useCallback } from 'react';
import useCurrentSpace from './useCurrentSpace'

import {
  useChainId as useFluentChainId,
  connect as connectFluent,
  Unit,
} from '@cfxjs/use-wallet'
import {
  useChainId as useMetaMaskChainId,
} from '@cfxjs/use-wallet/dist/ethereum'

export const useAccount = () => {
  const currentSpace = useCurrentSpace();
  const [account, setAccount] = useState(undefined);

  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      console.log('Please connect to Fluent Wallet.');
      setAccount(undefined);
    } else {
      setAccount(accounts[0]);
    }
  }, []);

  useEffect(() => {
    const fetchAccounts = async () => {
      if (!window.conflux) {
        return
      }
      
      try {
        const accounts = await window.conflux.request({ method: 'cfx_accounts' });
        handleAccountsChanged(accounts);
      } catch (err) {
        console.error('Error fetching accounts:', err);
        setAccount(undefined);
      }
    };

    fetchAccounts();

    if (window.conflux) {
      window.conflux.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.conflux) {
        window.conflux.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, [currentSpace, handleAccountsChanged]);

  return account;
};


export const useChainId = () => {
  const currentSpace = useCurrentSpace();
  const fluentChainId = useFluentChainId();
  const metaMaskChainId = useMetaMaskChainId();

  const [chainId, setChainId] = useState(1);

  useEffect(() => {
    const updateChainId = async () => {
      if (!window.conflux) {
        return
      }

      try {
        const newChainIdHex = await window.conflux.request({ method: 'cfx_chainId' });
    
        const newChainId = parseInt(newChainIdHex, 16);
    
        setChainId(newChainId);
      } catch (error) {
        console.error('Error fetching chainId:', error);
      }
    };

    updateChainId();

  }, [currentSpace, fluentChainId, metaMaskChainId]);

  return chainId;
};

function formatBalance (balance, decimals = 5) {
  const balanceInStandardUnit = balance / Math.pow(10, 18)
  return balanceInStandardUnit.toFixed(decimals)
};

export const useBalance = () => {
  const currentSpace = useCurrentSpace()
  
  const [balance, setBalance] = useState(undefined);
  const address = useAccount()

  useEffect(() => {
    setBalance(undefined)
    const fetchBalance = async () => {
      if (!currentSpace || !address || !window.conflux) return;
      
      try {
        const accounts = await window.conflux.request({ method: 'cfx_requestAccounts' })

        let newBalance;
        newBalance = await window.conflux.request({
          method: 'cfx_getBalance',
          params: [accounts[0]],
        })

        const balanceInDecimal = parseInt(newBalance, 16);
        setBalance(formatBalance(balanceInDecimal))
      } catch (error) {
        console.error('Error fetching balance:', error)
        setBalance(undefined)
      }
    };

    fetchBalance()
  }, [currentSpace, address])

  return balance;
};

export const useSendTransaction = () => {
  return async (txParams) => {
    try {
      // get user current address
      const accounts = await window.conflux.request({ method: 'cfx_requestAccounts' });

      // format transaction params
      const transactionParams = {
        from: accounts[0],
        ...txParams,
      };

      // send transaction
      const txHash = await window.conflux.request({
        method: 'cfx_sendTransaction',
        params: [transactionParams],
      });

      console.log('Transaction sent successfully:', txHash);
      return txHash;
    } catch (error) {
      console.error('Error sending transaction with Fluent:', error);
      throw error;
    }
  };
};


export const useTryActivate = () => {
  return connectFluent
}

export {Unit}
