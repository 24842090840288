import { Bar } from "react-chartjs-2";
import './index.css';

function RewardChart ({ chartData }) {
  return (
    <div className="p-6 chart-container bg-base-200 rounded-2xl" style={{ height: '100%' }}>
      <Bar
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function (tooltipItem) {
                  return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue + ' CFX';
                }
              }
            }
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: true,
                borderWidth: 1
              },
              ticks: {
                color: '#909497'
              }
            },
            y: {
              grid: {
                drawBorder: true,
                borderWidth: 1,
                color: '#e0e0e0',
                lineWidth: 1,
                tickLength: 0
              },
              ticks: {
                color: '#909497',
                stepSize: 0.3,
                maxTicksLimit: 8
              },
            }
          },
          minBarLength: 0.5,
          maxBarThickness: 50
        }}
      />
    </div>
  )
}

export default RewardChart;