import React, { useState } from 'react';
import { Modal, Button, Form, Typography, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { format } from 'js-conflux-sdk';
import { calculateGasMargin } from "../../../utils";
import { useAccount, useSendTransaction, Unit, useChainId } from '../../../hooks/useWallet';
import { CFX_BASE_PER_VOTE } from '../../../constants';

import usePoolContract from '../../../hooks/usePoolContract';
import useCurrentNetwork from '../../../hooks/useCurrentNetwork';
import useConflux from '../../../hooks/useConflux';
import BigNumber from 'bignumber.js';
import poolConfig from '../../../../pool.config';
import InputComponent from '../../components/Input';
import successImg from '../../../images/success.png';
import './index.css';

const { Text } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const TransactionModal = ({ type, title, available, onUpdate, triggerOperationUpdate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputCfx, setInputCfx] = useState('');
  const [inputError, setInputError] = useState('');
  const [loading, setLoading] = useState(false);
  const [txStatus, setTxStatus] = useState('pending');
  const [disabled, setDisabled] = useState(false);
  const [txHash, setTxHash] = useState('');
  const { isTestNetEnv } = useCurrentNetwork();

  const { contract: posPoolContract } = usePoolContract();
  const { conflux: confluxController, checkTransactionStatus } = useConflux();
  const chainId = useChainId();
  const accountAddress = useAccount();
  const sendTransaction = useSendTransaction();

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => {
    if (disabled) return;
    resetModal();
    setIsModalVisible(false);
  };

  const resetModal = () => {
    setInputCfx('');
    setInputError('');
    setTxStatus('pending');
  };

  const validateInput = (value) => {
    const isValidNumber = /^\d*\.?\d+$/.test(value);
    const withinAvailable = parseFloat(value) <= available;
    if (parseFloat(value) === 0) {
      setInputError('Amount must be greater than 0');
      return false;
    }
    if (type === 'Withdraw') {
      if (isValidNumber && Number(value) % 1000 === 0 && withinAvailable) {
        setInputError('');
        return true;
      }
      setInputError('Amount must be a multiple of 1000 and within available balance');
    } else if (type === 'Claim' && isValidNumber && withinAvailable) {
      setInputError('');
      return true;
    } else {
      setInputError('Please enter a valid amount within available balance');
    }
    return false;
  };

  const handleSelectAll = () => setInputCfx(available.toString());

  const estimateTransaction = async (type, value) => {
    const methods = {
      Claim: () => posPoolContract.claimReward(value),
      Withdraw: () => posPoolContract.withdrawStake(value)
    };
    const method = methods[type];
    const estimateData = await method().estimateGasAndCollateral({
      from: accountAddress,
      value: 0
    });
    return { data: method().data, estimateData };
  };

  const submitTransaction = async () => {
    if (!accountAddress) return message.error('Please connect Fluent');
    if (!inputCfx) return message.warning(`Please input the amount to ${type.toLowerCase()}`);

    setDisabled(true);
    setLoading(true);

    try {
      const value = type === 'Withdraw'
        ? new BigNumber(inputCfx).dividedBy(CFX_BASE_PER_VOTE).toString(10)
        : new BigNumber(inputCfx).multipliedBy(new BigNumber(10).pow(18)).toString(10);
        
      const { data, estimateData } = await estimateTransaction(type, value);
      const txParams = {
        to: format.address(isTestNetEnv ? poolConfig.testnet.contractAddress : poolConfig.mainnet.contractAddress, Number(chainId)),
        data,
        value: Unit.fromMinUnit(0).toHexMinUnit(),
        gas: Unit.fromMinUnit(calculateGasMargin(estimateData?.gasLimit || 0)).toHexMinUnit(),
        gasPrice: await confluxController.provider.call("cfx_gasPrice"),
        storageLimit: Unit.fromMinUnit(calculateGasMargin(estimateData?.storageCollateralized || 0)).toHexMinUnit()
      };

      const txHash = await sendTransaction(txParams);
      setTxHash(txHash);
      setTxStatus('pending');
      await checkTransactionStatus(txHash, setTxStatus);
      onUpdate();
      // make sure operation history is updated
      triggerOperationUpdate();
    } catch (error) {
      console.error('Transaction error:', error);
      message.warning('Transaction Rejected');
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };

  const onInputChange = (newValue) => {
    setInputCfx(newValue);
    validateInput(newValue);
  };

  return (
    <>
      <button className="px-3 py-1 ml-4 font-semibold text-black bg-white border rounded-md" onClick={showModal}>{type}</button>
      <Modal
        title={<span className='text-lg font-bold'>{title}</span>}
        centered
        bodyStyle={{ paddingBottom: 14 }}
        open={isModalVisible}
        onOk={submitTransaction}
        onCancel={handleCancel}
        footer={txStatus === 'pending' && [
          <Button type="text" key="cancel" onClick={handleCancel}>Cancel</Button>,
          <Button
            key="submit"
            disabled={inputError || loading}
            onClick={submitTransaction}
            style={{ borderRadius: 8, background: '#666666', color: 'white', border: 0, fontWeight: 700 }}
            className={`rounded-md border-none custom-button ${inputError ? '!bg-[#dbdcde]' : ''}`}
          >
            <div className="flex items-center">
              {loading && <Spin indicator={antIcon} style={{color: 'white' }} className="!mr-2" size="small" />}
              {type}
            </div>
          </Button>,
        ]}
      >
        {txStatus === 'pending' && (
          <Form layout="vertical">
            <Form.Item
              style={{ marginBottom: 12 }}
              label={<span className="text-base">Amount</span>}
              name="amount"
            >
              <InputComponent
                placeholder="0.00"
                onUpdate={onInputChange}
                size="small"
                hasPrefix={false}
                hasSuffix={true}
                available={available}
                modelValue={inputCfx}
                onSelectAll={() => handleSelectAll()}
              />
              {inputError && <Text type="danger">{inputError}</Text>}
            </Form.Item>
            <Text className='block mt-8 text-base font-medium'>
              <span className="mr-1 text-gray-400">Available:</span>
              <span>{available} CFX</span>
            </Text>
          </Form>
        )}
        
        {txStatus === 'success' && (
          <div className="text-center">
            <img src={successImg} alt="success" className="mx-auto w-14" />
            <h2 className="text-[#5ab56f] font-bold">Success</h2>
            <p className="my-4 text-gray-text">Your transaction was successfully processed.</p>
            <Button onClick={handleCancel} style={{ borderRadius: 8, background: '#666666', color: 'white', border: 0, fontWeight: 700 }} className="block ml-auto">Confirm</Button>
          </div>
        )}

        {txStatus === 'failed' && (
          <div className="text-center ">
            <h2 className='text-red-500'>Transaction Failed</h2>
            <p className='my-4 text-gray-text'>There was an error processing your transaction. Please try again.</p>
            <Button onClick={handleCancel} style={{ borderRadius: 8, background: '#666666', color: 'white', border: 0, fontWeight: 700 }} className="block ml-auto">Confirm</Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TransactionModal;