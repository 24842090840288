import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { getRewardInfo } from '../../api';
import { useAccount, useChainId} from '../../hooks/useWallet';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatNumber } from '../../utils';
import usePoolContract from '../../hooks/usePoolContract';
import useCurrentNetwork from '../../hooks/useCurrentNetwork';
import BigNumber from 'bignumber.js';
import TransactionModal from './components/TransactionModal';

function Summary({ triggerOperationUpdate }) {
  const [apr, setApr] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [balance, setBalance] = useState(0);
  const [unClaimedRewards, setUnClaimedRewards] = useState(0);
  const [withdrawableAmount, setWithDrawableAmount] = useState(0);

  const account = useAccount();
  const chainId = useChainId();
  const { isTestNetEnv } = useCurrentNetwork();

  const { contract: posPoolContract } = usePoolContract();
  
  const getRewardSummary = async () => {
    if (!account) {
      setApr(0);
      setTotalRewards(0);
      setBalance(0);
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet': 'mainnet',
      address: account,
    }

    const res = await getRewardInfo(params);

    setApr(res.data.average_apr);
    setTotalRewards(res.data.total_earn);
    setBalance(res.data.balance);
  }

  const getWithdrawableAmount = async () => {
    if (!account) {
      setWithDrawableAmount(0);
      return;
    }

    const res = await posPoolContract.userSummary(account)

    setWithDrawableAmount(res[3][0] * 1000)
  }

  const getUnclaimedRewards = async () => {
    if (!account) {
      setUnClaimedRewards(0);
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const [reward1, reward2, reward3] = await posPoolContract.getUserRewardInfo(account)

    const bigNumberValue = new BigNumber(reward2.toString());
    const decimalValue = bigNumberValue.dividedBy(new BigNumber(10).pow(18));
    const decimalString = decimalValue.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];

    setUnClaimedRewards(decimalString)
  }

  const handleUpdate = useCallback(() => {
    getRewardSummary();
    getWithdrawableAmount();
    getUnclaimedRewards();
  }, [account, chainId]);

  useEffect(() => {
    handleUpdate();
  }, [account, chainId, handleUpdate])

  return (
    <div className="p-6 my-6 bg-base-200 rounded-2xl">
      <div className="grid grid-cols-1 gap-2 lg:gap-6 lg:grid-cols-3">
        <div className="lg:col-span-1">
          <span className="block text-base font-medium text-gray-text">Total Rewards</span>
          <strong className="block mt-2 text-2lg">{formatNumber(totalRewards, 8)} CFX</strong>
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">APR</span>
          <strong className="block mt-2 text-2lg">{formatNumber(apr, 8)} %</strong>
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">Balance</span>
          <strong className="block mt-2 text-2lg">{formatNumber(balance, 8)} CFX</strong>
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">Unclaimed CFX</span>
          <div className="flex items-center mt-2">
            <strong className="block text-2lg">{unClaimedRewards} CFX</strong>
            {Number(unClaimedRewards) > 0 && 
              <TransactionModal
                type="Claim" 
                title="Claim Rewards"
                available={unClaimedRewards}
                onUpdate={handleUpdate}
                triggerOperationUpdate={triggerOperationUpdate}
              />
            }
          </div>
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">
            <span className="mr-2">Withdrawable Amount </span>
            <Tooltip title="It will take few minutes to update this value after your unstake" color="white" key="white" overlayInnerStyle={ {color: '#333'} }><InfoCircleOutlined /></Tooltip>
          </span>
          <div className="flex items-center mt-2">
            <strong className="block text-2lg">{Number(withdrawableAmount) ? Number(withdrawableAmount)  : 0} CFX</strong>
            { Number(withdrawableAmount) > 0 && 
              <TransactionModal
                type="Withdraw"
                title="Request Withdrawal"
                available={withdrawableAmount}
                onUpdate={handleUpdate}
                triggerOperationUpdate={triggerOperationUpdate}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
  
  
}

export default Summary;